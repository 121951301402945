@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$border-radius-big: 10px !default;

%active-state {
	color: white;
	background: var(--color-active, $active-color);
	position: relative;
	padding: 0;

	svg {
		width: 16px;
		height: 16px;
	}
}

%select-range-support {
	content: "";
	position: absolute;
	top: 0;
	background: $grey-light;
	width: 50%;
	height: 100%;
	z-index: -1;
}

.date-calendar-input {
	$this: &;

	position: relative;
	box-sizing: border-box;

	&:focus-visible {
		outline: none; // pas de bordure bleu au focus via tab clavier
	}

	&__container {
		display: flex;
		flex: 2;
	}

	&__close_icon {
		position: absolute;
		right: 4px;
		top: 20px;
		color: var(--color-gray-500, $input-border-color);
	}

	&__label {
		position: absolute;
		top: 18px;
		left: 15px;
		display: flex;
		align-items: center;
		transition: top 0.1s ease-out, font-size 0.1s ease-out;
		color: $grey-dark;
		pointer-events: none; // fix empeche de saisir sur device ios

		@include gridle_state(md) {
			top: 15px;
		}
	}

	&__input {
		@extend %font-normal;

		width: 100%;
		height: 100%;
		padding-top: 16px;
		padding-bottom: 0; // the content is not centered on ios
		padding-left: 12px;
		box-sizing: border-box;
		font-size: 1.4rem;
		outline: none;
		appearance: none;
		border-radius: $border-radius-big;
		-webkit-appearance: none; // delete top big border in ios
		display: flex;
		align-items: center;
		background: white;
	}

	&__placeholder {
		color: $grey-dark;
	}

	&__dash {
		margin-left: 3px;
		margin-right: 3px;
	}

	&__departure,
	&__return {
		&--touched {
			#{$this}__label {
				@extend %input-label-touched;

				left: 0;
				padding-right: 12px;
				padding-left: 12px;
				top: 10px;

				@include gridle_state(md) {
					top: 7px;
				}
			}
		}

		&--error {
			#{$this}__label {
				@extend %font-bold;

				color: var(--color-warning-500);
			}
		}

		&--opened {
			.date-calendar-input__input {
				background: var(--color-gray-300, $grey-light);
			}
		}
	}

	&__departure {
		&--opened {
			.date-calendar-input__input {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}

		#{$this}__close_icon {
			right: 4px;
		}
	}

	&__return {
		.date-calendar-input__input {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		&--opened {
			.date-calendar-input__input {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}

	&__popover {
		min-height: 495px;
		box-sizing: border-box;
		background: white;
		padding: 25px 100px 16px;
		z-index: 12;
		border-bottom-left-radius: $border-radius-big;
		border-bottom-right-radius: $border-radius-big;
		box-shadow: 0 8px 10px rgba(0, 0, 0, 0.06);
	}

	&__calendar {
		.react-calendar__navigation__next2-button,
		.react-calendar__navigation__prev2-button {
			display: none;
		}

		.react-calendar__viewContainer {
			display: flex;
			justify-content: space-between;
			gap: 50px;
		}

		.react-calendar__month-view {
			width: 50%;
		}

		.react-calendar__month-view__days__day--neighboringMonth {
			visibility: hidden;
		}

		.react-calendar__navigation__label {
			text-transform: capitalize;
			display: flex;
			gap: 50px;
			padding-left: 0;
			padding-right: 0;
			align-items: center;
			justify-content: center;
			pointer-events: none;
			background: none;
			border: none;
			outline: none;
		}

		.react-calendar__navigation {
			display: flex;
			align-items: center;
			margin-bottom: 16px;
		}

		.react-calendar__navigation__arrow {
			background: none;
			border: none;
			cursor: pointer;
			padding-left: 0;
			padding-right: 0;

			svg {
				width: 33px;
				height: 32px;
				color: black;
			}

			&:disabled {
				cursor: not-allowed;

				svg {
					color: var(--color-gray-500, $input-border-color);
				}
			}
		}

		.react-calendar__navigation__label__divider {
			display: none;
		}

		.react-calendar__navigation__label__labelText {
			@extend %font-bold;

			font-family: var(--font-family-bold);
			font-size: var(--font-size-xl);
			width: 50%;
		}

		.react-calendar__navigation__prev-button {
			position: absolute;
			left: 112px;
		}

		.react-calendar__navigation__next-button {
			position: absolute;
			right: 112px;
		}

		.react-calendar__month-view__weekdays {
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			box-sizing: border-box;
			border-top: 1px solid var(--color-gray-400, $grey-medium);
			border-bottom: 1px solid var(--color-gray-400, $grey-medium);
			margin-bottom: 10px;
			padding: 16px 0;

			&__weekday {
				text-align: center;
				width: 36px;
				color: $grey-dark;

				abbr {
					text-decoration: none;

					&::after {
						content: ".";
					}
				}
			}
		}

		.date-calendar-input-tile__tile.react-calendar__tile.react-calendar__tile--hasActive {
			@extend %active-state;
		}

		.date-calendar-input-tile {
			padding-right: 10px;
			padding-left: 10px;

			&__tile {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin: 4px 0;
				cursor: pointer;
				border: none;
				background: none;
				color: $black;
				font-size: 1.4rem;
				box-sizing: border-box;
				aspect-ratio: 1/1;
				font-family: var(--font-family);


				abbr {
					width: 100%;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				&[disabled] {
					pointer-events: none;
					color: $grey-medium;
				}

				&.react-calendar__tile--hover {
					background: $grey-light;

					&End {
						border-radius: 0 10px 10px 0;
					}
				}

				&.react-calendar__tile--range {
					background: var(--color-gray-300, $grey-light);

					.date-picker-calendar-tile__price,
					.date-picker-calendar-tile__price--alternative {
						display: none;
					}

					&.react-calendar__tile--hasActive {
						color: white;

						.date-picker-calendar-tile__price {
							.amount {
								color: white;
							}

							&:not(.date-picker-calendar-tile__price--best) {
								.amount__value {
									color: white;
								}
							}

							&--public {
								.amount__value {
									color: white;
								}
							}
						}
					}

					&.react-calendar__tile--rangeStart {
						@extend %active-state;

						overflow: visible !important;
						border-bottom-left-radius: $border-radius-big;
						border-top-left-radius: $border-radius-big;

						&::before {
							@extend %select-range-support;

							right: 0;
						}
					}

					&.react-calendar__tile--rangeEnd {
						@extend %active-state;

						overflow: visible !important;
						border-bottom-right-radius: $border-radius-big;
						border-top-right-radius: $border-radius-big;

						&::before {
							@extend %select-range-support;

							left: 0;
						}
					}

					&.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd {
						&::before {
							display: none;
						}

						&.react-calendar__tile--hover {
							&::before {
								display: block;
								transform: translateX(100%);
							}
						}
					}

					&.react-calendar__tile--rangeBothEnds {
						border-bottom-right-radius: 0;
						border-top-right-radius: 0;
					}
				}
			}
		}

		&-footer {
			border-top: 1px solid var(--color-gray-400, $grey-medium);
			padding-top: 16px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			&-label {
				width: 100%;
			}

			&-button {
				width: fit-content;
				padding: 16px;
			}
		}
	}

	.date-calendar-input__loader {
		padding: 0 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		height: inherit;
		gap: 6px;
		cursor: wait;

		svg {
			border-radius: 40px;
		}
	}
}
