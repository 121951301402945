@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$border-radius-big: 5px !default;

.travellers-room-button {
	$this: &;

	position: relative;
	box-sizing: border-box;

	&:focus-visible {
		outline: none; // pas de bordure bleu au focus via tab clavier
	}

	&__label {
		position: absolute;
		top: 18px;
		left: 56px;
		display: flex;
		align-items: center;
		transition: top 0.1s ease-out, font-size 0.1s ease-out;
		color: $grey-dark;
		pointer-events: none; // fix empeche de saisir sur device ios

		@include gridle_state(md) {
			z-index: 1;
			color: $black;
			top: 15px;
			left: 15px;
		}
	}

	&__input {
		height: 100%;

		// the content is not centered on ios
		padding: 16px 12px 0;
		box-sizing: border-box;
		font-size: 1.4rem;
		outline: none;
		appearance: none;
		border-radius: $border-radius-big;
		box-shadow: $input-shadow-box;
		-webkit-appearance: none; // delete top big border in ios
		display: flex;
		align-items: center;
		background: white;
		position: unset !important; // popover put position relative and make disapear label
	}

	&__header {
		display: flex;
		align-items: center;
		color: $header-text-color;
		height: 60px;
		min-height: 60px;
		width: calc(100% - 40px);
		justify-content: space-between;
		padding: 0 20px;
		box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
		z-index: 1;
		background-color: $header-background-color;
	}

	&--touched {
		#{$this}__label {
			@extend %input-label-touched;

			top: 10px;
			text-overflow: ellipsis;
			display: inline;
			white-space: nowrap;
			left: 0;
			padding-right: 12px;
			padding-left: 12px;

			@include gridle_state(md) {
				top: 7px;
			}
		}
	}

	&--error {
		#{$this}__label {
			color: var(--color-warning-500);
		}

		#{$this}__input {
			color: var(--color-warning-500);
		}
	}

	&--opened {
		.travellers-room-button__input {
			background: var(--color-gray-300, $grey-light);
		}
	}

	&__popover {
		box-sizing: border-box;
		background: white;
		z-index: 12;
		border-bottom-left-radius: $border-radius-big;
		border-bottom-right-radius: $border-radius-big;
		box-shadow: 0 8px 10px rgba(0, 0, 0, 0.06);

		@include gridle_state(md) {
			width: 322px; // pour etre aligné avec le bord droite du CTA
			// depend de width de #travellersRooms dans SmartDPSearchForm.scss
		}

		&--sticky {
			#{$this}__popover-body {
				max-height: 60vh;
				overflow: auto;
				padding-bottom: 84px;

				.travellers-room-form__validate-button {
					position: absolute;
					bottom: 0;
				}
			}
		}
	}

	.passengers-dounter-display {
		width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.travellers-room-button__loader {
		padding: 0 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		height: inherit;
		gap: 6px;
		cursor: wait;

		svg {
			border-radius: 40px;
		}
	}
}
